//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.overflow-table .rdt_TableCell div {
	margin-top: 1rem;
	margin-bottom: 1rem;
	white-space: normal !important;
	overflow: visible !important;
}

.list-group-flush-horizontal .list-group-flush.list-group-horizontal > .list-group-item {
	border-bottom-width: 1px;
	border-top-width: 0;
}

.list-group-flush-horizontal .list-group-flush.list-group-horizontal:last-child > .list-group-item {
	border-bottom-width: 0;
}

.list-group-flush-horizontal .list-group-flush.list-group-horizontal.list-item-2 > .list-group-item {
	min-width: 50%;
}

.list-group-flush-horizontal .list-group-flush.list-group-horizontal.list-item-1 {
	min-width: 100%;
	flex-direction: column;
}

.fade-enter {
	opacity: 0;
	transform: translateY(-50%);
  }
  
  .fade-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: all 300ms ease-in;
  }
  
  .fade-exit {
	opacity: 1;
	transform: translateY(0);
  }
  
  .fade-exit-active {
	opacity: 0;
	transform: translateY(-50%);
	transition: all 300ms ease-out;
  }
  
  .fade-transition {
	&.fade-enter, &.fade-exit {
	  position: absolute;
	  top: 50%;
	  left: 0;
	  right: 0;
	}
  }
  
  
